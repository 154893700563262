.tab {
  display: inline-block;
  margin: 0;
  color: rgba($text02, 1);
  font-size: 14px;
  line-height: 24px;
  padding: 16px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  user-select: none;

  &.active {
    font-weight: 600;
    color: rgba($neutral01, 1);
    border-color: rgba($neutral01, 1);
  }
}

@media only screen and (max-width: 1300px) {
  .tab {
    font-size: 13px;
    padding: 16px 12px;
  }
}
